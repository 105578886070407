import * as React from 'react';

// components
import Gallery from '../components/Gallery';
import Layout from '../components/Layout';

// data
import portfolio from '../images/portfolio';

// styles
import '../styles/index.scss';

export default function IndexPage() {

  return (
    <Layout path="/illustration" header="Illustrations" title="Illustration / GwynevereRose.com" >
      <Gallery images={portfolio.views.illustration} />
    </Layout>
  );
}
